import React , { useState, useContext, Fragment, useEffect} from 'react'
import { AuthContext, isAdmin } from '../authentication/AuthProvider';
import { ListItemText, List, ListItem, ListItemIcon, Divider, Button, Typography} from '@mui/material';
import {AddCircleOutline,SmartScreen, Screenshot, PhoneIphone, PhoneAndroid, LocalPolice} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterList from '../buttons/FilterList';
import AddDevice from './AddDevice';



export default function DeviceList({devicesinfo, buildingId, flatId, setRefresh}) {

    const [devicesData, setDevicesData] = useState(null);
    const [initialDevices, setInitialDevices] = useState(null);
    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const [showAddDevice, setShowAddDevice] = useState(false);  

    useEffect(() => {
        setDevicesData(devicesinfo);
        setInitialDevices(devicesinfo);
    }, [devicesinfo]);

    const onFiltered = (filtered) => {
        setDevicesData(filtered);
      };

      const navigate = useNavigate();
    
      const handleDeviceClick = (e, deviceId) => {
        navigate(`/building/`+buildingId + "/0/" + deviceId);
    };   

    const startAdd = () => {
      setShowAddDevice(false);
      //workaround to fix the issue with the dialog not showing - need to render component twice.
      setTimeout(() => setShowAddDevice(true), 0);
    }

return(
    <Fragment>
      <List 
      sx={{
        pt: 0,
        bgcolor: 'background.paper',
        boxShadow: 3,
        minWidth: 500,
        marginBottom: 2
      }}>
      <ListItem sx={{backgroundColor:"grey.200"}} key="device-list-header">
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <Typography variant='h4' color="GrayText"  >רשימת מכשירים</Typography>
        </div>
      </ListItem>
      <ListItem alignItems='flex-start' key="device-list-subheader">
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <FilterList data={initialDevices} onFilter={onFiltered} fields="['friendlyName','deviceid']"/>    
            {isAdmin && <Button variant="contained" size="medium" startIcon={<AddCircleOutline  />} onClick={()=>startAdd()} > 
              &nbsp;הוספת מכשיר&nbsp;
            </Button>}
        </div>
      </ListItem>
        {devicesData && devicesData.map((device) => (
          <div key={device.deviceid} >
            <Divider variant="fullwidth" component="li" />
            <ListItem alignItems='flex-start' onClick={(e)=>{handleDeviceClick(e,device.deviceid)}}
             sx={{
              '&:hover': {
                backgroundColor: 'grey.200' // change this to your preferred color
              },
            }}>
              <ListItemIcon>
                {device.devicetype==='base'?<Screenshot />:device.devicetype==='guard'? <LocalPolice/> :<SmartScreen />}
              </ListItemIcon>
              <ListItemText id={device.deviceid} primary={device.friendlyName}  />
              <ListItemText id={device.deviceid}  secondary={device.devicetype==='base'?'אינטרקום':device.devicetype=='guard'?'שומר' :'מוניטור'} />
            </ListItem>
          </div>
            ))}
      </List>
          <AddDevice show={showAddDevice} buildingId={buildingId} flatId="0" onAddDevice={setRefresh} deviceType='base' onComplete={()=>setShowAddDevice(false)}/>
    </Fragment>
);
}