import React , { useState, Fragment, useEffect, useContext} from 'react'
import { ListItemText, List, ListItem, ListItemIcon, Divider, Typography, Button} from '@mui/material';
import {Apartment, Publish} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterList from '../buttons/FilterList';
import { AuthContext } from '../authentication/AuthProvider';



export default function FlatList({flatinfo, buildingId, setRefresh}) {

    const [flatsData, setFlatsData] = useState(null);
    const [initialFlats, setInitialFlatsData] = useState(null);
    const { requestHeaders, isAdmin} = useContext(AuthContext);


    useEffect(() => {
        setFlatsData(flatinfo);
        setInitialFlatsData(flatinfo);
    }, [flatinfo]);

    const onFiltered = (filtered) => {
        setFlatsData(filtered);
      };

      const navigate = useNavigate();
    
    const handleClick = (e,flatid) => {
      //no drill down to lobby flat
      if(flatid==="0" || flatid===0)
        return;
      navigate(`/building/${buildingId}/${flatid}`);
    };

    const importFile = () => {
        navigate(`/building/${buildingId}/import`);
    }

return(
    <Fragment>
      <List className='list-container'
      sx={{
        pt: 0,
        bgcolor: 'background.paper',
        boxShadow: 3,
        minWidth: 500,
        marginBottom: 2
      }}>
        <ListItem sx={{backgroundColor:"grey.200"}} key="flat-list-header">
          <div className="d-flex flex-row justify-content-center align-items-center w-100">
            <Typography variant='h4' color="GrayText">רשימת דירות</Typography>
          </div>
        </ListItem>

        <ListItem alignItems='flex-start' key="flat-list-subheader">
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <FilterList data={initialFlats} onFilter={onFiltered} fields="['flatname','familyName','username','deviceid','ACSID']"/>    
            {isAdmin && <Button variant="contained" size="medium" startIcon={<Publish  />} onClick={()=>importFile()} > 
              &nbsp; יבוא דיירים &nbsp;
            </Button>}
          </div>
        </ListItem>
        {flatsData && flatsData.map((flat) => (
          <div key={flat.flatid}>
            <Divider variant="fullwidth" component="li" />
            <ListItem alignItems='flex-start' onClick={(e)=>{handleClick(e,flat.flatid)}}
             sx={{
              '&:hover': {
                backgroundColor: 'grey.200' // change this to your preferred color
              },
            }}>
              <ListItemIcon>
                      <Apartment />
              </ListItemIcon>
              <ListItemText id={flat.id}  primary={flat.familyName} />
              <ListItemText id={flat.id} secondary={flat.flatname}  />
            </ListItem>
          </div>
            ))}
      </List>

    </Fragment>
);
}