import { AppBar,  Box, Toolbar, Typography } from "@mui/material";
import React from 'react'
import c4ulogo from '../images/connect4u-logo.png';
import Profile from "../authentication/Profile";

export default function Header() {
  
  return (
    <Box sx={{ flexGrow: 1, mb:10 }}>
      <AppBar>
        <Toolbar>
          <a href="/building">
            <img src={c4ulogo} alt="Connect4U Logo" style={{height: "50px", width: "50px"}}></img>
          </a>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          &nbsp;
          </Typography>
          <Profile/>
        </Toolbar>
      </AppBar>
    </Box>
  );
}