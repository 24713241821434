import React, { useState, Fragment, useContext} from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../authentication/AuthProvider';
import Header from '../layout/Header';
import { BlobServiceClient } from '@azure/storage-blob';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";


export default function Import() {
    const { buildingId } = useParams();
    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");

    const [selectedFile, setSelectedFile] = useState(null);
    const { requestHeaders } = useContext(AuthContext);
    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    
    const getSaSToken = async () => {
        try {
            const targetServer = process.env.REACT_APP_API_HOST;
            const api = `api/getImportSasToken`;

            fetch(targetServer + api, {
                method: 'GET',
                headers: requestHeaders
            }).then(response => {
                if (response.ok) {
                    console.info('fetch import SaS token - response ok');
                    return response;
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            });
        }
        catch (error)
        {
            console.error('בעיה בהעלאת קובץ: ' + error);
            track({severity: 'error', name: 'בעיה בהעלאת קובץ: ' + error});
                throw new Error('בעיה בהעלאת קובץ: ' + error);
        }
    };

    const onFileUpload = async () => {
        if (!selectedFile) return;
      
        // Upload the file to Azure Blob Storage using the SAS token
        const storageAccountName = "c4udevstorage";
        const sasToken = getSaSToken();
        const containerName = "import";
        const contentLength = selectedFile.size;
        console.info(`Uploading file ${selectedFile.name} with size ${contentLength} bytes to container ${containerName} in storage account ${storageAccountName} using SAS token ${sasToken}`);
        // Initialize Blob Service Client
         const blobService = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net/?${sasToken}`);
    
        // Get the container
         const containerClient = blobService.getContainerClient(containerName);
    
        // Upload the file
         await containerClient.uploadBlockBlob(selectedFile.name, selectedFile, contentLength);
    
        // Optionally, set metadata or perform other actions
    
        // Refresh the list of files in the container
        // const blobs = await containerClient.listBlobsFlat();
        // console.log('Uploaded files:', blobs);
    };



    return (
        <Fragment>
            <Header />
            <div>
                <input type="file" onChange={onFileChange} />
                <button onClick={onFileUpload}>Upload</button>
            </div>
        </Fragment>
    );
}