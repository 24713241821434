import React, {  useState, useEffect } from 'react';
import {Button, Dialog,DialogTitle,DialogContent, DialogContentText, DialogActions} from '@mui/material';

function Confirmation(properties) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [properties.open]);

  const handleConfirm = () => {
    properties.onConfirm();
    setOpen(false);
  };

  const handleCancel = () => {
    properties.onCancel();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">
      {properties.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      {properties.content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleConfirm}>כן</Button>
      <Button onClick={handleCancel} autoFocus>
        לא
      </Button>
    </DialogActions>
  </Dialog>



  );
}


export default Confirmation;