import React , {useContext, useState, useEffect, Fragment} from 'react'
import { AuthContext } from './authentication/AuthProvider';
import Header from './layout/Header';
import {TailSpin} from "react-loader-spinner";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { Exception } from '@microsoft/applicationinsights-web';

function DeviceRegistrations() {
  const [registrations, setRegistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { requestHeaders, isAdmin} = useContext(AuthContext);
  const appInsights = useAppInsightsContext();
  const track = useTrackEvent(appInsights, "ManagementApp");

  useEffect(() => {
    async function fetchRegistrations() {
        setIsLoading(true);
      const targetServer = process.env.REACT_APP_API_HOST
      const api = 'api/deviceRegistrations'
      fetch(targetServer + api, {
        method: 'GET', 
        headers: requestHeaders
      }).then(response =>  {
        if (!response.ok) {
          throw new Error(response.status); // Throw an error if the response status is not in the 200-299 range
        }
        return response.json();
      }).then(data => {
        setRegistrations(data);
        }
        )
        .catch(error => {
            track({severity: 'error', name: 'error in fetching device registrations: ' + error});
            throw new Exception("error in fetching device registrations: " + error);
        });
      setIsLoading(false);
     
  }

    fetchRegistrations();
  }, [isAdmin, requestHeaders]);

  return (
    <Fragment> 
    <Header/>
    {isLoading ? <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} /> :
      
    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <h2>Device Registrations</h2>
      <ul>
        {registrations.map(registration => (
          <li key={registration.id}>
            {registration.deviceName} - {registration.deviceId}
          </li>
        ))}
      </ul>
    </div>
    }
    </Fragment>
  );
}

export default DeviceRegistrations; 