import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import Buildings from "./components/building/Buildings";
import BuildingInfo from './components/building/BuildingInfo';
import FlatInfo from './components/flat/FlatInfo';
import WelcomePage from './components/WelcomePage';
import AddUser from './components/user/AddUser';
import RemoveAccount from './components/RemoveAccount';
import DeviceInfo from './components/device/DeviceInfo';
import Import from './components/building/Import';
import { AuthContext } from './components/authentication/AuthProvider';
import "./style.css"
import Devices from './components/device/Devices';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppInsightsErrorBoundary,AppInsightsContext  } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import DeviceRegistrations from './components/DeviceRegistrations';
import { Buffer } from 'buffer';
window.Buffer = Buffer;
toast.configure();

function App() {
   var reactPlugin = new ReactPlugin();
  // *** Add the Click Analytics plug-in. ***
  /* var clickPluginInstance = new ClickAnalyticsPlugin();
     var clickPluginConfig = {
       autoCapture: true
  }; */
   var appInsights = new ApplicationInsights({
      config: {
          connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
          // *** If you're adding the Click Analytics plug-in, delete the next line. ***
          enableAutoRouteTracking: true,
          extensions: [reactPlugin]
       // *** Add the Click Analytics plug-in. ***
       // extensions: [reactPlugin, clickPluginInstance],
       //   extensionConfig: {
         //   [reactPlugin.identifier]: { history: browserHistory }
         // *** Add the Click Analytics plug-in. ***
         // [clickPluginInstance.identifier]: clickPluginConfig
          //}
      }
  });
  appInsights.loadAppInsights();

  return (
    <div className="App">
       <AppInsightsErrorBoundary onError={() => <h1>it's not you, it's us....</h1>} appInsights={reactPlugin}>
       <AppInsightsContext.Provider value={reactPlugin}>
       <ToastContainer />
      <Router>
        <Routes>
          <Route path="/building/:buildingId/:flatId" element={<ProtectedRoute component={<FlatInfo />} />} />
          <Route path="/building/:buildingId/:flatId/:deviceId" element={<ProtectedRoute component={<DeviceInfo />} />} />
          <Route path="/building/:buildingId" element={<ProtectedRoute component={<BuildingInfo />} />} />
          <Route path="/building/:buildingId/import" element={<ProtectedRoute component={<Import />} />} />
          <Route exact path="/building/" element={<ProtectedRoute component={<Buildings />} />} />
          <Route exact path="/devices/" element={<ProtectedRoute component={<Devices />} />} />
          <Route exact path="/registrations/" element={<ProtectedRoute component={<DeviceRegistrations />} />} />
          <Route exact path="/add-user/:token" element={<AddUser />} />
          <Route exact path="/remove-account/" element={<RemoveAccount />} />
          <Route exact path="/" element={<WelcomePage />} />
        </Routes>
      </Router>
      </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    </div>

  );
}

const ProtectedRoute = ({ component }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const forceAuthentication = process.env.REACT_APP_ALLOW_UNAUTHENTICATED !== 'true';
  if (!isAuthenticated && !isLoading && forceAuthentication) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/" />;
  }

  return component;
};


export default App;
