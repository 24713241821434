import c4ulogo from '../images/connect4u-logo.png'

export default function Footer() {
    return  <footer className="footer" id="footer">
                <div className="d-flex flex-row justify-content-around align-items-center h-100 w-100">
                <div style={{flex:0.3}} id="legal">
                        <a href="https://connect4u.co.il/?page_id=774" className="footer-text"> תנאי מדיניות</a>
                        &nbsp;&nbsp;
                        <a href="https://connect4u.co.il/?page_id=789" className="footer-text"> מדיניות פרטיות</a>
                    </div>
                    <div style={{flex:0.4}} id="appstores">
                <a href="https://play.google.com/store/apps/details?id=com.connect4you.connect4umobile ">
                  <img src='/se-android-store.svg' alt="Google Play" style={{height: "40px", width: "auto"}}></img>
                </a>
                <a href="https://apps.apple.com/app/connect-4u/id6464481755">
                  <img src={"/se-apple-store.svg"} alt="Apple Store" style={{height: "40px", width: "auto"}}></img>
              </a>
              </div>
                    <div className="d-flex flex-row  justify-content-center align-items-center" style={{flex:0.3}} id="copyright">
                        <div className="footer-text">
                            ©  כל הזכויות שמורות ל
                            <a href="https://www.connect4u.co.il" target='_blank' rel="noreferrer">
                             קונקט פור יו </a> 
                             בע"מ 
                        </div>
                    </div>
              </div>
             </footer>
}