import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './authentication/AuthProvider';
import googleLogin from './images/btn_google_signin_light_normal_web@2x.png';
/*
This component is used to add authenticate a user to complete registration to a building
*/
function RemoveAccount ()  {
  const { isAuthenticated, requestHeaders} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userRemovedFailed, setuserRemovedFailed] = useState(false);
  const [userRemovedCompleted, setuserRemovedCompleted] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(false);
  
  const authenticationRedirect = window.location.href;
  // Function to decode the JWT token
  
  const handleConfirmation = () => {
    // Get the value from the text box
    // Call the method to set the final user name
    setUserConfirmed(true);
  };

  useEffect(() => {
    // Check if the user is authenticated and the userRemovedCompleted and isLoading states are false
    if (!isAuthenticated || !userConfirmed || userRemovedCompleted || isLoading || userRemovedFailed) {
      return;
    }
    setIsLoading(true);
    //send put request to add user to building
    const targetServer = process.env.REACT_APP_API_HOST
    const api = `api/RemoveUser`;

    fetch(targetServer + api, {
        method: 'DELETE',
        headers: requestHeaders
      }).then(response => {
      
        if (response.ok) {
          console.log('User deleted successfully! response:', response);
          setuserRemovedCompleted(true);
          setIsLoading(false);
        } else {
          const errorData = response.text();
          console.error('Failed to remove user! Error:', errorData);
          setIsLoading(false);
          setuserRemovedFailed(true);
        }
      }).catch((error) => {
          setIsLoading(false);
          setuserRemovedFailed(true);
          console.error(error);
          })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, userRemovedCompleted, userConfirmed]); 

  return (
    <div>
      {!isAuthenticated ? (
        <div>
          <h2> שלום </h2>
          <p direction='rtl'>אנא התחבר עם החשבון שאתה רוצה להסיר </p>
          <p><a href={`/.auth/login/google?post_login_redirect_url=${authenticationRedirect}`}>
           <img src={googleLogin} alt='google login'></img>
           </a>
          </p>
          <p><a href={`/.auth/login/apple?post_login_redirect_url=${authenticationRedirect}`}>
           Login with Apple
           </a>
          </p>
        </div>
      ) :
      (isAuthenticated && !userRemovedCompleted && !isLoading && !userRemovedFailed) ?
      <div className='user-name' style={{ direction: 'rtl' }}>
        <p direction='rtl'>האם אתה בטוח שאתה רוצה להסיר את עצמך? </p>
        
        <button className="confirmation-button" onClick={handleConfirmation}>כן הסירו אותי</button>
        </div>
       
      : 
       (isAuthenticated && !userRemovedCompleted && isLoading) ?
      (
        <div>
            <p>כמה שניות...</p>
        </div>
      ) : (isAuthenticated && userRemovedCompleted) ?
      (
        <div>
            <p>משתמש הוסר בהצלחה</p>
        </div>
      ) 
      : <div>
          <p>אירעה תקלה בהסרתך - אנא נסה שנית במועד מאוחר יותר</p>
        </div>
      }
    </div>
  );
};

export default RemoveAccount;

