import React , {useContext, useState, useEffect} from 'react'
import { AuthContext } from '../authentication/AuthProvider';
import { ListItemText, List, ListItem, ListItemIcon,Divider} from '@mui/material';
import { TabletAndroid, Search} from '@mui/icons-material';
import Footer from '../layout/Footer';

function Devices(){

    const [devicesData, setDevicesData] = useState(null);
    const [initialDevices, setInitialDevicesData] = useState(null);

    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const [refreshFlag, setRefreshFlag] = useState(false);
    
    useEffect(() => {
        const targetServer = process.env.REACT_APP_API_HOST
        const api = 'api/getbuildings'
        fetch(targetServer + api, {
          method: 'GET', // or any other HTTP methoda
          headers: requestHeaders
        }).then(response =>  {
          if (!response.ok) {
            throw new Error(response.status); // Throw an error if the response status is not in the 200-299 range
          }
          return response.json();
        }).then(data => {
            setDevicesData(data);
            setInitialDevicesData(data);
          }
          )
          .catch(error => console.error(error));
      }, [refreshFlag, requestHeaders]);
      

      const handleSearch = (e) => {
        const input = e.target.value;
        const filtered = filterBuildings(input);
        setDevicesData(filtered);
      };

    const filterBuildings = (input) => {
        if (!input) {
          return initialDevices;
        }
        const regex = new RegExp(input, 'gi');
        const filtered = initialDevices.filter(
          (device) =>
            (device.title ? device.title.match(regex) : false) || device.fullAddress.city.match(regex) || device.fullAddress.address.match(regex)
        );
        return filtered;
      };
    return(
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
        {devicesData ? 
        <div className='d-flex flex-column'>
          <h1> רשימת מכשירים</h1>
        <List 
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          minWidth: 500,
          borderRadius: 2
        }}>
          <ListItem alignItems='flex-start' key={"header"} bgcolor="red">
            <div className="d-flex flex-row justify-content-around align-items-center w-100">
          <Search />
          <input
                className='form-control form-control-sm ml-3 w-50 search-input'
                type='text'
                placeholder='חיפוש'
                aria-label='Search'
                name='buildings'
                onChange={handleSearch}
              />
              </div>
                    </ListItem>
          {devicesData && devicesData.map((device) => (
            <div>
              <Divider variant="fullwidth" component="li" />
              <ListItem alignItems='flex-start' key={device.id} 
               sx={{
                '&:hover': {
                  backgroundColor: 'grey.200' // change this to your preferred color
                },
              }}>
                <ListItemIcon>
                        <TabletAndroid />
                </ListItemIcon>
                <ListItemText  primary={device.title}  />
                <ListItemText secondary={device.fullAddress.address} />
              </ListItem>
            </div>
    
              ))}
        </List>
        <Footer/>
        </div>
          : (
            <p>...טוען</p>
          )}
          
        </div>
      );

}

export default Devices;