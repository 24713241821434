import { Button, Stack, Typography,  TextField, setRef } from '@mui/material';
import React, { useState,useContext, Fragment, useEffect } from 'react';
import { Delete, Edit, Send, Cancel, Refresh} from '@mui/icons-material';
import Confirmation from '../inputs/Confirmation';
import { AuthContext } from '../authentication/AuthProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useBuildingList from '../commands/useBuildingList';
import useBuildingData from '../commands/useBuildingData';



export default function BuildingHeader({buildingData, buildingId, refreshData}){

    const [buildingTitle, setBuildingTitle] = useState("");
    const [buildingAddress, setBuildingAddress] = useState("");
    const [buildingCity, setBuildingCity] = useState("");
    const [buildingCountry, setBuildingCountry] = useState("");
    const [buildingEmail, setBuildingEmail] = useState("");
    const [doorCode, setDoorCode] = useState("");
    const [doorCodeError, setDoorCodeError] = useState(false);
    const [doorDelay, setDoorDelay] = useState("");
    const [doorDelayError, setDoorDelayError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteButtonText, setDeleteButtonText] = useState("מחיקה");
    const [editButtonText, setEditButtonText] = useState("עדכון");
    const [refreshButtonText, setRefreshButtonText] = useState("רענון");
    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const [refreshBuildingList, setRefreshBuildingList] = useState(false);
    const [refreshBuildingData, setRefreshBuildingData] = useState(false);
    const navigate = useNavigate();

    let buildingList = useBuildingList(refreshBuildingList);
    buildingData = useBuildingData(buildingId, refreshBuildingData);
    useEffect(() => {
        if(buildingData!=null)
            resetEditFields();
    }, [buildingData]);

    const onSuccess = () => {
        exitEditMode();
        setRefreshBuildingData(true);
        //refresh building list
        toast.success('הבניין עודכן בהצלחה');
    }

    const onFailure = () => {   
        toast.error('שגיאה בעדכון הבניין');
        exitEditMode();
    }

    const refreshBuilding = () => {
        setRefreshBuildingData(true);
    }

    const updateBuilding = async () => {
        const targetServer = process.env.REACT_APP_API_HOST

        const building = {
            title: buildingTitle,
            address: buildingAddress,
            city: buildingCity,
            country: buildingCountry,
            doorcode: doorCode,
            doordelay: doorDelay,
            email: buildingEmail
        };
        const api = 'api/building/' + buildingId;
        try {
          const response = await fetch(targetServer + api ,  {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(building)
        });
        console.log('Update building sucessful! response:');
        
          onSuccess();
        } catch (error) {
          console.error(error);
          resetEditFields();
          onFailure();
        }
      };

      const resetEditFields = () => {
        setBuildingTitle(buildingData.title);
        setBuildingAddress(buildingData.fullAddress.address);
        setBuildingCity(buildingData.fullAddress.city);
        setBuildingCountry(buildingData.fullAddress.country);
        setDoorCode(buildingData.doorcode);
        setDoorDelay(buildingData.doordelay);
        setBuildingEmail(buildingData.email);
      }

      const deleteBuilding = async () => {
        const targetServer = process.env.REACT_APP_API_HOST
        const api = 'api/building/' + buildingId;
        try {
          const response = await fetch(targetServer + api ,  {
            method: 'DELETE',
            headers: requestHeaders
          });
          
            if (!response.ok) {
                console.log(response.status);
                onFailure();
            }
            else{
                setRefreshBuildingList(true);
                toast.success('הבניין נמחק בהצלחה');
                navigate(-1);
            }
        } catch (error) {
          console.error(error);
          onFailure();
        }
      };
    const confirmDelete = () => {
        //if in edit mode, cancel edit mode
        if(editMode){
            resetEditFields();
           exitEditMode();
        }
        else //user requested to delete, show confirm dialog
            setOpenDialog(true);
    }
    const cancelDelete = () => {
        setOpenDialog(false);
    }
    const DeleteBuilding = () => {
        deleteBuilding().then(() => {
            //navigate(-1);
        });
    }
    const  enterEditMode = () => {
        if(editMode){
            updateBuilding();
            exitEditMode();

        }
        else{
        setEditMode(true);
        setDeleteButtonText("ביטול");
        setEditButtonText("שמירה");
        }
    }

    const exitEditMode=()=>{
        setEditMode(false);
        setDeleteButtonText("מחיקה");
        setEditButtonText("עדכון");
    }

    const handleDoorCodeChange = (value) => {
        //check if doorcode is 4 digits
        const doorcodeRegex = /^\d{4}$/;
        setDoorCode(value);
        const isValid = doorcodeRegex.test(value);
        if(isValid){
            setDoorCodeError(false);
        }
        else{
             setDoorCodeError(true);
        }
        }
    const handleDoorDelayChange = (value) => {
        //reg ex that only allows for a single digit of 1-5
        const doorDelayRegex = /^[1-5]$/;
        setDoorDelay(value);
        const isValid = doorDelayRegex.test(value);
        if(isValid){
            setDoorDelayError(false);
        }
        else{
            setDoorDelayError(true);
        }
        }
    return(
        <Fragment>
            {buildingData ?
            <Stack direction="row" marginTop="60px" marginX="30px" borderBottom={2} borderColor={"GrayText"} justifyContent={"space-between"}>
                {editMode?
                <Stack direction="column" alignItems="start" >
                    {editMode && 
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }} color={"GrayText"}>שם הבניין </Typography>
                    }
                    <TextField placeholder="שם הבניין" value={buildingTitle} onChange={(e)=>setBuildingTitle(e.target.value)}/>
                    {editMode && 
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }} color={"GrayText"}>כתובת </Typography>
                    }
                    <Stack direction="row">
                        <TextField placeholder="כתובת הבניין" value={buildingAddress} onChange={(e)=>{setBuildingAddress(e.target.value)}}/>
                        <TextField placeholder="עיר" value={buildingCity} onChange={(e)=>{setBuildingCity(e.target.value)}}/>
                        {editMode && 
                            <TextField placeholder="מדינה" value={buildingCountry} onChange={(e)=>{setBuildingCountry(e.target.value)}}/>
                        }
                    </Stack>
                    {editMode &&
                    <Fragment>
                        <Typography variant="h8" component="div" sx={{ flexGrow: 1 }} color={"GrayText"}>דלת כניסה </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <TextField placeholder="השהיית פתיחה" value={doorDelay} onChange={(e)=>{handleDoorDelayChange(e.target.value)}} 
                             error={doorDelayError}
                             helperText={doorDelayError ? "השהיית הדלת צריכה להיות בין 1 ל-5 שניות" : ""}
                             inputProps={{
                                maxLength: 1,
                              }}/>
                        </Stack>
                    </Fragment>
                }
                {editMode && <Fragment>
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }} color={"GrayText"}>דוא"ל בניין </Typography>
                    <TextField placeholder='דוא"ל בניין' value={buildingEmail} onChange={(e)=>{setBuildingEmail(e.target.value)}}/>
                    </Fragment>}
                </Stack>
                :
                <Stack direction="column" alignItems="start" >
               
                <Typography variant="h3" component="div" sx={{ flexGrow: 1 }} color={"GrayText"}>
                    {buildingTitle}
                </Typography>

                <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} color={"GrayText"}>
                {buildingAddress}, {buildingCity}
                </Typography>
                <Typography> השהייה: {doorDelay} שניות</Typography>
                <Typography>דוא"ל בניין: {buildingEmail}</Typography>
                
            </Stack>
}
{isAdmin && 
            <Stack direction="column" alignItems="end" spacing={1}>
               { editMode && 
            <Button variant="contained" size="medium" startIcon={<Cancel />}  sx={{ width: '120px' }} onClick={confirmDelete}>
                &nbsp; {deleteButtonText} &nbsp;
              </Button>
                }
              <Button variant="contained" size="medium" startIcon={editMode?<Send />:<Edit  />}  sx={{ width: '120px' }} onClick={enterEditMode}> 
                &nbsp; {editButtonText} &nbsp;
              </Button>
              <Button variant='contained' size="medium" startIcon={<Refresh />} sx={{width:'120px'}} onClick={refreshBuilding}>
                &nbsp; {refreshButtonText} &nbsp;
              </Button>
              {openDialog &&
                <Confirmation open={openDialog} title={"מחיקת בניין"} content={"האם למחוק את הבניין?"} onConfirm={DeleteBuilding} onCancel={cancelDelete}/>
                }
            </Stack>
}
        </Stack>
            :""
            }
        </Fragment>
    );
}