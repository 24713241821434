import { useState, useEffect, useContext } from 'react';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { AuthContext } from '../authentication/AuthProvider';

function useBuildingList(forceRefresh = false) {
    const [buildingsList, setBuildingsList] = useState([]);
    const { requestHeaders } = useContext(AuthContext);

    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");

    useEffect(() => {
        //try to load cached data from session storage (react does not persist state between page loads)
        console.log('requestHeaders: ' + requestHeaders);
        const fetchData = async () => {
        let cachedData = sessionStorage.getItem('buildingsList') ? JSON.parse(sessionStorage.getItem('buildingsList')) : null;
        if (!forceRefresh && cachedData) {
            console.log('using cached buildings list, : ' + cachedData);
            setBuildingsList(cachedData);
        } else {
            console.log('fetching fresh buildings list');
            try {
                const targetServer = process.env.REACT_APP_API_HOST;
                const api = `api/GetBuildings`;

                fetch(targetServer + api, {
                    method: 'GET',
                    headers: requestHeaders
                }).then(response => {
                    if (response.ok) {
                        console.info('fetch buildings list - response ok');
                        return response.json();
                    } else {
                        throw new Error(response.status + " - " + response.statusText);
                    }
                }).then(data => {
                    setBuildingsList(data);
                    sessionStorage.setItem('buildingsList', JSON.stringify(data));
                    }).catch(error => {
                        console.log('fetch buildings list failed: ' + error);
                        track({severity: 'error', name: 'fetch buildings list failed: ' + error});
                        throw new Error(error);
                    });
                }
            catch (error)
            {
                console.error('בעיה בטעינת רשימת בניינים: ' + error);
                track({severity: 'error', name: 'בעיה בטעינת רשימת בניינים: ' + error});
                    throw new Error('בעיה בטעינת רשימת בניינים: ' + error);
            }
        };
        };

        fetchData();
        }, [requestHeaders, forceRefresh]);
        
    return {buildingsList};
};

export default useBuildingList;

