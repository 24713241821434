import { useState, useEffect, useContext } from 'react';

import { AuthContext } from '../authentication/AuthProvider';

function useBuildingData(buildingId, forceRefresh) {
    const [buildingData, setBuildingData] = useState(null);
    const { requestHeaders } = useContext(AuthContext);

    useEffect(() => {

            const fetchData = async () => {
            const cachedData = JSON.parse(sessionStorage.getItem(`building-${buildingId}`));
            console.log('useBuildingData - forceRefresh: ' + forceRefresh + ', cachedData:', cachedData);
            if (!forceRefresh && cachedData) {
                console.info('using cached buildings data');
                setBuildingData(cachedData);
            }
            else{
                console.info('fetching building data for building ' + buildingId + ' from server');
                if(buildingId === null || buildingId === undefined){
                    console.log('buildingId is null, setting buildingData to null');
                    setBuildingData(null);
                }
                else{
                    console.log('fetching fresh building data for building ' + buildingId);
                    try {
                        const targetServer = process.env.REACT_APP_API_HOST;
                        const api = `api/building/${buildingId}`;

                        fetch(targetServer + api, {
                            method: 'GET',
                            headers: requestHeaders
                        }).then(response => {
                            if (response.ok) {
                                //return the response json for the next promise in the chain
                                return response.json();
                            } else {
                                throw new Error(response.status + " - " + response.statusText);
                            }
                        }).then(data => {
                            setBuildingData(data);
                            sessionStorage.setItem(`building-${buildingId}`, JSON.stringify(data));
                        }).catch(error => {
                            console.error('Error fetching building data:', error);
                        });
                        } catch (error) {
                            console.error('Error fetching building data:', error);
                        };
                    }
            }
        };
        fetchData();
    }, [buildingId, requestHeaders, forceRefresh]);

    try{
        return buildingData;
    }
    catch (error){
        console.log('Error parsing building data:', error);
        return null;
    }
};

export default useBuildingData;

