// BuildingInfo.js

import React, { useEffect, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import useBuildingData from '../commands/useBuildingData';
import Footer from '../layout/Footer';
import Header from '../layout/Header';
import FlatList from '../flat/FlatList';
import { Grid, setRef } from '@mui/material';
import BuildingHeader from './BuildingHeader';
import DeviceList from '../device/DeviceList';
import { TailSpin } from 'react-loader-spinner';

export default function BuildingInfo() {
  
  const { buildingId } = useParams();
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [buildingData, setBuildingData] = useState(null);
  const inititalBuildingData = useBuildingData(buildingId, refreshFlag); //will be called whenever refreshFlag or buildingId are updated

  useEffect(() => {
    if(inititalBuildingData !== null && inititalBuildingData !== undefined){
        setBuildingData(inititalBuildingData);
    }
    }, [inititalBuildingData]);

  return (
    <Fragment>
    <Header/>
    {buildingData ? 
      <Fragment>
        <BuildingHeader buildingData={buildingData} buildingId={buildingId} refreshData={() => {setRefreshFlag(true);}}/>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center" style={{marginTop:"50px"}} >
          <div style={{ marginBottom:"50px"}}>
              <Grid container spacing={3} >
                <Grid item xs={12} md={6}>
                  <FlatList flatinfo={buildingData.flats} buildingId={buildingId} setRefresh={() => {
                        setRefreshFlag(true);
                      }}></FlatList>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DeviceList devicesinfo={buildingData.flats[0].devices} buildingId={buildingId} flatId={0} setRefresh={() => {
                        setRefreshFlag(true);
                      }} ></DeviceList>
                </Grid>
              </Grid>
            <Footer />
          </div>
          </div>
        </Fragment>
    :
    <div className="w-100 d-flex flex-column justify-content-center align-items-center" style={{marginTop:"50px"}} >
    <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
    </div>
      }
    </Fragment>
    ); 
}