import React, { useEffect, useState } from 'react';
import {TextField, Stack, Typography } from "@mui/material";
export default function MacAddressInput(props) {

const [macAddress, setMacAddress] = useState(props.value);
const [edit, setEdit] = useState(props.edit);
const [error, setError] = useState(false);

useEffect(() => {
    setEdit(props.edit);
}, [props.edit]);


const updateMacAddress = (value) => {
//create an array of the 6 parts of the mac address
  //check if combinedValue is a valid mac address based on a regex
  const regex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  setMacAddress(value);
  if(regex.test(value)){
    setError(false);
    props.onChange(value);
  }
  else{
    setError(true);
  }
}

  return (
    <Stack direction="column" spacing={0} >
    
      {edit && <TextField value={macAddress}  onChange={(event) => {updateMacAddress(event.target.value);} } 
            autoFocus
            required
            margin="dense"
            type="text"
            variant="standard"
            dir='ltr'
            label="כתובת MAC"
            InputLabelProps={{
              style: { direction: 'rtl' },
            }}
            fullWidth/>}
            {!edit && <Typography dir="ltr">{macAddress}</Typography>}
    {error && <Typography variant="caption" color="error">כתובת MAC שגויה</Typography>}
    </Stack>
  );
}
