// BuildingInfo.js

import React, { useState, useEffect, useContext, Fragment } from 'react';
import { AuthContext } from '../authentication/AuthProvider';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useBuildingData from '../commands/useBuildingData';
import Header from '../layout/Header';
import BuildingHeader from '../building/BuildingHeader';
import { Button, TextField, Stack, List,ListItem, ListItemIcon, ListItemText, Divider, Typography,RadioGroup, Radio, Checkbox, FormControlLabel, FormLabel} from '@mui/material';
import { Apartment, Cancel, Edit, Send, SmartScreen, PhoneIphone, AddCircle, VerifiedUser,ShieldOutlined } from '@mui/icons-material';
import AddDevice from '../device/AddDevice';
import AddKey from '../device/AddKey';
import { useNavigate } from 'react-router-dom';
import {TailSpin} from "react-loader-spinner";
import Confirmation from '../inputs/Confirmation';

export default function FlatInfo() {
  
  const [flatData, setFlatData] = useState(null);
  const {buildingId, flatId} = useParams();
  const [editMode, setEditMode] = useState(false);
  const [deleteButtonText, setDeleteButtonText] = useState('מחק דירה');
  const [editButtonText, setEditButtonText] = useState('עדכון');
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [deviceType, setDeviceType] = useState('monitor'); //monitor or phone
  const [showAddKey, setShowAddKey] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const buildingData = useBuildingData(buildingId, refreshFlag); //will be called whenever refreshFlag or buildingId are updated

  //flat properies
  const [allowMultiDevice, setAllowMultiDevice] = useState(false);
  const [allowAddPhone, setAllowAddPhone] = useState(false);
  const [tagCount, setTagCount] = useState(0); //number of tags in the flat
  const [callMode, setCallMode] = useState('individual');
  const [familyName, setFamilyName] = useState('');
  const [flatName, setFlatName] = useState('');
  const [maxNumOfPhones, setMaxNumOfPhones] = useState(5); //default value

  const { requestHeaders, isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();
 
  useEffect(() => {
    setFlatDataFromBuildingData(buildingData);
  }, [buildingData]);

  useEffect(() => {
    if(refreshFlag===true){
      setRefreshFlag(false); // reset refresh flag
    }
  }, [refreshFlag]);

  const openAddDialog = (deviceType) =>{
    if(deviceType === 'nfc')
      {
        setShowAddKey(true);
        return;
      }
      else
      {
        setDeviceType(deviceType);
        setShowAddDevice(true);
      }
  }

  const setFlatDataFromBuildingData = (_buildingData) => {
    if (!_buildingData || !_buildingData.flats) {
      return;
    }
    console.log('_buildingData.flats', _buildingData.flats);
    const flat = _buildingData.flats.find(flat =>
        {
            console.log('flat.flatid:', flat.flatid, typeof (flat.flatid));
            return (typeof flat.flatid === "number" ? flat.flatid.toString() : flat.flatid) === flatId;
        }  
    );
    setFlatData(flat);
    setFamilyName(flat.familyName);
    setFlatName(flat.flatname);
    setCallMode(flat.callMode ? String(flat.callMode) : 'individual'); // or 'all'
    setAllowMultiDevice(flat.allowMonitorAndPhones);
    if(flat.maxNumOfPhones){
      setMaxNumOfPhones(flat.maxNumOfPhones);
    }
    console.log('flat:', flat);
  };

  useEffect(() => {
    // Check if buildingData is available and flatData is not set
    setFlatDataFromBuildingData(buildingData);
    isAddingPhoneAllowed();
    setTagCount(flatData  && flatData.keys ? flatData.keys.length : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatData]);
  
  const isAddingPhoneAllowed = () => {
    if (!flatData) {
      return false;
    }
    const monitorsExist = flatData.devices.filter(device => device.devicetype === 'monitor').length > 0;
    const phoneCount = flatData.devices.filter(device => device.devicetype === 'phone').length;
    console.log('monitorsExist:', monitorsExist);
    console.log('phoneCount:', phoneCount);
    //if flat has 5 phones (or more), don't allow adding any more
    if(phoneCount>=maxNumOfPhones ){
      setAllowAddPhone(false);
      return;
    }
    else{
      if(!monitorsExist){
        setAllowAddPhone(true);
        return;
      }
      if(monitorsExist && (allowMultiDevice || isAdmin)){
        setAllowAddPhone(true);
        return;
      }

    }
    setAllowAddPhone((!monitorsExist && phoneCount<=5) || (isAdmin && allowMultiDevice));
    console.log('allowAddPhone:', allowAddPhone);
  }
  
  const updateFlat = async () => {
    const targetServer = process.env.REACT_APP_API_HOST;
    const api = `api/building/${buildingId}/${flatId}`;
    
    let updatePayload = {};
    if (familyName  && familyName !== flatData.familyName) {
      updatePayload.familyName = familyName;
    }
    if (flatName && flatName !== flatData.flatname) {
      updatePayload.flatname = flatName;
    }
    if(callMode !== flatData.callMode){
      updatePayload.callMode = callMode;
    }
    updatePayload.allowMultiDevice = allowMultiDevice;
    updatePayload.maxNumOfPhones =Math.floor(maxNumOfPhones); //use Math.floor to make sure the value is an integer

    console.info('Update flat called with flat ', updatePayload);
    try {
      const response = await fetch(targetServer + api, {
        method: 'PUT',
        headers: requestHeaders,
        body: JSON.stringify(updatePayload),
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Flat updated successfully! Response:', data);
        toast.success('דירה עודכנה בהצלחה');
        setRefreshFlag(true);
        setEditMode(false);
      }
      else if (!response.ok) {
        const errorData = await response.text();
        console.error('Failed to update flat! Error:', errorData);
        toast.error(`בעיה בעדכון הדירה:  ${errorData}`);
        setEditMode(false);
      }
  }
  catch (error) {
    toast.error('בעיה בעדכון הדירה: ', error);
    console.error(error);
    setEditMode(false);
  }
};

const  enterEditMode = () => {
  if(editMode){
      updateFlat();
      exitEditMode();
  }
  else{
      setEditMode(true);
      setDeleteButtonText("ביטול");
      setEditButtonText("שמירה");
  }
}

const exitEditMode=()=>{
  setEditMode(false);
  setDeleteButtonText("מחיקה");
  setEditButtonText("עדכון");
}

const resetEditFields = () => {

}


const confirmDelete = () => {
  //if in edit mode, cancel edit mode
  if(editMode){
      resetEditFields();
     exitEditMode();
  }
}

const allowMultiDEviceChanged = (e) => {
    setAllowMultiDevice(e.target.checked);
}

const callModeChanged = (e) => {
  setCallMode(e.target.value); //update the call mode
  setShowConfirm(true);// show confirmation dialog; if the user clicks cancel setCallMode value will get reversed
}
const cancelCallModeChange = () => {
  if(callMode==='all'){
    setCallMode('individual');
  }
  else
{
    setCallMode('all');
}
  setShowConfirm(false);
}

const handleDeviceClick = (e, deviceId) => {
  navigate(`/building/${buildingId}/${flatId}/${deviceId}`);
};  

const updateMaxNumOfPhones = (e) => {
  setMaxNumOfPhones(e.target.value);
}

  return (
    <Fragment>
    <Header/>
    <BuildingHeader buildingData={buildingData} buildingId={buildingId} refreshData={() => {setRefreshFlag(true);}}/>

    <div className="w-100 d-flex flex-column justify-content-center align-items-center" style={{marginTop:"50px"}} >
    {buildingData ? 
    <div>
      {flatData ?
      <div className="flat-info">
      <List 
      sx={{
        pt: 0,
        bgcolor: 'background.paper',
        boxShadow: 3,
        minWidth: 500,
        marginBottom: 2
      }}>
        <ListItem alignItems='center' key="device-list-subheader" sx={{backgroundColor: 'grey.200' }}>
          <ListItemIcon>
                <Apartment/>
          </ListItemIcon>
          <ListItemText align="center">
              <Stack direction="row">
                <ListItemText align="center">
                  {!editMode && flatData.flatname}
                  {editMode && <TextField id="flatName" label="שם דירה" variant="standard" value={flatName} onChange={(e) => setFlatName(e.target.value)}   sx={{ width: '80px' }}/>}
                </ListItemText>
                <ListItemText align="center">
                  {!editMode && flatData.familyName}
                  {editMode && <TextField id="familyName" label="שם משפחה" variant="standard" value={familyName} onChange={(e) => setFamilyName(e.target.value)}  sx={{ width: '150px' }}/>}
                </ListItemText>
              </Stack>
          </ListItemText>
          <ListItemText align="start">
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            {editMode && 
              <Button variant="contained" size="medium" startIcon={<Cancel />} sx={{ width: '120px', marginX:'10px'}} onClick={confirmDelete}>
                &nbsp; {deleteButtonText} &nbsp;
              </Button>}
              <Button variant="contained" size="medium" startIcon={editMode?<Send />:<Edit  />}  sx={{ width: '120px', marginX:'10px' }} onClick={enterEditMode}> 
                &nbsp; {editButtonText} &nbsp;
              </Button> 
            </Stack>
          </ListItemText>
        </ListItem>
        <ListItem alignItems="flex-start" key="device-list-subheader1" sx={{backgroundColor: 'grey.150' }}>
          <ListItemText sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start'}}>
            <Stack direction="column"  sx={{ width: '100%' }}>
              {!editMode && <Typography align="center">מצב קריאה: {callMode==='all'?'משפחתי':'יחידני'}</Typography>}
              {!editMode && isAdmin && <Typography align="center">אפשר טלפון + מוניטור: {flatData.allowMonitorAndPhones?'כן':'לא'}</Typography>}
              {!editMode && isAdmin && <Typography align="center">מספר טלפונים מאושר: {maxNumOfPhones}</Typography>}
              {editMode &&
              <Stack direction="row" alignItems="center">
                <FormLabel id="demo-radio-buttons-group-label">מצב קריאה</FormLabel>
                <RadioGroup row aria-label="callMode" name="callMode" value={callMode} onChange={callModeChanged}>
                  <FormControlLabel value="individual" control={<Radio />} label="יחידני" />
                  <FormControlLabel value="all" control={<Radio />} label="משפחתי" />
                </RadioGroup>
              </Stack>
              }
              {editMode && isAdmin && 
                <Stack direction="row" alignItems="center">
                  <Checkbox
                    checked={allowMultiDevice}
                    onChange={(e) => { allowMultiDEviceChanged(e)}}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>אפשר טלפון + מוניטור</Typography>
                </Stack>
                }
                 {editMode && isAdmin && 
                <Stack direction="row" alignItems="center">
                  <Typography>מספר טלפונים מאושר: </Typography>
                  <input type="number" min="5" max="10" onInput={(e)=>{updateMaxNumOfPhones(e)}} value={maxNumOfPhones}/>
                </Stack>
                }
            </Stack>
          </ListItemText>
          <ListItemText align="start">
          {isAdmin &&
            <Button variant="contained" size="medium" startIcon={<AddCircle />} onClick={()=>openAddDialog("nfc")} sx={{ width: '100px', marginX:'10px' }}>&nbsp; NFC&nbsp;</Button>
          }
          </ListItemText>
          {isAdmin && <ListItemText align="start" sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <Typography>{tagCount}</Typography>
          </ListItemText>
          }
        </ListItem>
      {flatData.devices.map((device) => (
        <Fragment>
          <Divider variant="fullwidth" component="li" />
          <ListItem key={device.deviceid}
            onClick={(e)=>{handleDeviceClick(e,device.deviceid)}}
              alignItems='flex-start'   sx={{
            '&:hover': {
              backgroundColor: 'grey.200' // change this to your preferred color
            },
          }}>
            <ListItemIcon>
              {device.devicetype === 'monitor' ? <SmartScreen /> : < PhoneIphone/>}
            </ListItemIcon>
            <ListItemText >
            <Typography align="center">
              {device.displayName}
            </Typography>
            </ListItemText>
            {device.devicetype==='phone' && device.isAuthenticated &&        
            <ListItemIcon>
              <VerifiedUser/>
            </ListItemIcon>
            }
            {device.devicetype==='phone' && !device.isAuthenticated &&        
            <ListItemIcon>
              <ShieldOutlined/>
            </ListItemIcon>
            }
          </ListItem>
        </Fragment>
      ))}
      <ListItem alignItems='flex-start' key='buttons' >
      {isAdmin &&
        <Button variant="contained" size="medium" startIcon={<AddCircle />} onClick={()=>openAddDialog("monitor")} sx={{ width: '120px', marginX:'10px' }}>&nbsp; מכשיר&nbsp;
        </Button>
      }
      {allowAddPhone &&
        <Button variant="contained" size="medium" startIcon={<AddCircle />} onClick={()=>openAddDialog("phone")} sx={{ width: '120px', marginX:'10px' }}>&nbsp; דייר&nbsp;
        </Button>
      }
      </ListItem>
    </List>
              <AddDevice show={showAddDevice} buildingId={buildingId} flatId={flatId}  onAddDevice={()=>setRefreshFlag(true)} onComplete={()=>setShowAddDevice(false)} deviceType={deviceType}/>
              <AddKey show={showAddKey} buildingId={buildingId} flatId={flatId} onAddKey={()=>setRefreshFlag(true)} onComplete={()=>setShowAddKey(false)}/>
              {showConfirm && <Confirmation title="זהירות!" content="שינוי מצב דירה דורש סגירה ופתיחה מחדש של האפליקציה בכל מכשיר, האם להמשיך?" onConfirm={()=>setShowConfirm(false)} onCancel={()=>cancelCallModeChange()}/>}
    </div>
      :
      <h2>דירה לא ידועה</h2>
     }
    </div>
    : <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
    }
    </div>
    </Fragment>
    )
  
}

