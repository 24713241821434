import React, { createContext, useState, useEffect } from 'react';
import jwt from 'jwt-decode' // import dependency
// Create a new context
export const AuthContext = createContext();

// Create a provider component to manage the authentication state
export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [username, setUsername] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [requestHeaders, setHeaders] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState('');
   
  const refreshTokens = async () => {
    console.log('Refreshing tokens');
    const response = await fetch('/.auth/refresh', {
      method: 'GET'
    });
    if (response.ok){
      setAccessToken(null);
      sessionStorage.removeItem('user_data');
    }
  };

  /*retrieve the access token
  */
  
  useEffect(() => {
    const api = '/.auth/me';

    if (process.env.NODE_ENV === 'development') {
      setIsAdmin(true);
    }

    const fetchAuthenicationToken = async (authenticationData) => {
      
      const api = authenticationData[0].provider_name === 'google' ? '.auth/login/google?access_type=offline' : '.auth/login/apple';
      const targetServer = process.env.REACT_APP_API_HOST;
      const response = await fetch (targetServer + api, {
        method: 'POST',
        body: JSON.stringify({
          "access_token": authenticationData[0].access_token,
          "id_token": authenticationData[0].id_token
        })});
      if (!response.ok) {
          setIsAuthenticated(false);
          setIsLoading(false);
          return;
      }
      const data = await response.json();

      setAccessToken(data.authenticationToken);
      setHeaders({
        'X-ZUMO-AUTH': `${data.authenticationToken}`,
        'Content-Type': 'application/json' // adjust the content type as per your API requirements
      })
      setIsLoading(false);
      setIsAuthenticated(true);
      
    }

    
    const setFieldsFromAuthData = (data) => {
      
      const decoded_token = jwt(data.access_token); // decode your token here
      
      setUsername(decoded_token.name);
      setIsAdmin(data.provider_name==='aad');
      setAccessToken(data.access_token);
      setUserId(data.user_id);
      setHeaders({
        'Authorization': `Bearer ${data.access_token}`,
        'Content-Type': 'application/json' // adjust the content type as per your API requirements
      });
      setIsAuthenticated(true);
      setIsLoading(false);
      //if running locally, set to admin
    }

    const userDataFromSession = sessionStorage.getItem('user_data');

    if (userDataFromSession) {
      var jsonUserDataFromSession = JSON.parse(userDataFromSession);
      //check if the cached user data is still valid
      if (new Date().getTime() < new Date(jsonUserDataFromSession.expires_on)) {
        setFieldsFromAuthData(jsonUserDataFromSession);
        return;
      }
      else {
        refreshTokens();
      }
    }

    fetch(api)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(data => {
        setUserData(data[0]);
        
        if (data[0].provider_name === 'google' || data[0].provider_name === 'apple') {
          for (let user_claim of data[0].user_claims) {
            if (user_claim.typ === 'name') {
              setUsername(user_claim.val);
            }
          }
          setUserId(data[0].user_id);
          fetchAuthenicationToken(data);
        } else if (data[0].provider_name === 'aad') {
          sessionStorage.setItem('user_data', JSON.stringify(data[0]));
          setFieldsFromAuthData(data[0]);
          
        }
        
      })
      .catch(error => {
        console.log(error);
        setIsAuthenticated(false);
        setIsLoading(false);
        setUsername('Unauthorized user');
      });
  }, [accessToken]);

  return (
    // Provide the authentication state values to the components
    <AuthContext.Provider value={{ userData, username, accessToken, isAuthenticated, isLoading, requestHeaders, refreshTokens, isAdmin, userId }}>
      {children}
    </AuthContext.Provider>
  );
};
