import React , {useContext} from 'react'
import { Navigate } from "react-router-dom";
import { AuthContext } from './authentication/AuthProvider';
import c4ulogo from './images/connect4u-logo.png';
import Footer from './layout/Footer';
import {Button, Typography, ListItemText, List, ListItem } from '@mui/material';

function WelcomePage() {

  const {isAuthenticated, isLoading} = useContext(AuthContext);
  
  if (isLoading) {
    return <div>Loading</div>;
  }
  
  if (!isAuthenticated) {
    return   <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <List className='list-container'
    sx={{
      pt: 0,
      bgcolor: 'background.paper',
      boxShadow: 3,
      minWidth: 500,
      marginBottom: 2
    }}>
      <ListItem sx={{backgroundColor:"grey.200"}}>
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <img src={c4ulogo} alt="Connect4U Logo" style={{height: "80px", width: "80px"}}></img>
          <Typography variant="h4" color="GrayText"> Connect 4U</Typography>
        </div>
    </ListItem>
    <ListItem sx={{backgroundColor:"grey.200"}}>
      <ListItemText sx={{textAlign:"center"}}>
        <Typography variant='h5' color={'GrayText'}>מערכת הניהול</Typography></ListItemText>
    </ListItem>
    <ListItem >
    <div className="d-flex flex-row justify-content-center align-items-center w-100">
      <Button variant="outlined" style={{margin:"10px"}} href="/.auth/login/aad?post_login_redirect_url=/">כניסת עובדים</Button>
      <Button variant="contained" color="primary" style={{margin:"10px"}} href="/.auth/login/google?post_login_redirect_url=/">כניסת לקוחות</Button>
    </div>
    </ListItem>
    </List>
    <Footer/>
    </div>
  }
  return <Navigate to="/building" />;
}

export default WelcomePage;