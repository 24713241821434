import { useContext, useEffect, useState } from "react";
import { AuthContext } from './AuthProvider';
import { Stack,  Menu, MenuItem } from "@mui/material";
import {AccountCircle} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

export default function Profile() {
    const {username, userId} = useContext(AuthContext);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const location = window.location.pathname;
    const navigate = useNavigate();

    // Ugly workaround to prevent the login page from showing up for the add-user route
    if (location.match('add-user')){
      return null;
    }
    if (location.match('remove-account')){
      return null;
    }

    const handleLogout = () => {

      // Clear sessionStorage
      console.log('logging out');
      sessionStorage.clear();
      handleClose();
      window.location.replace('/.auth/logout?post_logout_redirect_uri=/');
    };
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Stack direction="row" spacing={1} alignItems={"center"} >
          <AccountCircle fontSize="large" onClick={handleMenu}/>
          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{mt:5}}
              >
                
                <MenuItem divider disabled sx={{color:"black"}}>{username}</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
          </Stack>

    );
  }