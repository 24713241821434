import React , {useContext, useState, useEffect, Fragment} from 'react'
import { toast } from 'react-toastify';
import { AuthContext } from '../authentication/AuthProvider';
import Footer from '../layout/Footer';
import { ListItemText, List, ListItem, ListItemIcon,Divider, Button, Typography} from '@mui/material';
import {Apartment,AddCircleOutline, Refresh} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddBuilding from './AddBuilding';
import Header from '../layout/Header';
import FilterList from '../buttons/FilterList';
import {TailSpin} from "react-loader-spinner";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import useBuildingList from '../commands/useBuildingList';

 function Buildings() 
{
  console.log('Buildings page loaded');
  const appInsights = useAppInsightsContext();
  const track = useTrackEvent(appInsights, "ManagementApp");
  
  const { isAdmin} = useContext(AuthContext);
    const [buildingsList, setBuildingsList] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false); //used to trigger a refresh of the buildings list
    const [showAddBuilding, setShowAddBuilding] = useState(false);
    const navigate = useNavigate();

    const initialBuildings = useBuildingList(refreshFlag);

    const handleBuildingClick = (e, buildingid) => {
      navigate(`/building/`+buildingid);
    };

    useEffect(() => {
      console.log('useBuildingListCompleted use effect, initialBuildings: ' + initialBuildings);
        
      //only exectue once useBuildingList is completed
        if(initialBuildings !== null && initialBuildings !== undefined && initialBuildings.buildingsList !== null && initialBuildings.buildingsList !== undefined){
            //when initial buildings list is loaded, set the buildings list
            console.log('received ' + initialBuildings.buildingsList.length + ' buildings');
            setBuildingsList(initialBuildings.buildingsList);
          }

        if(!isAdmin && buildingsList !== undefined && buildingsList !=null ){
          track({severity: 'info', name: 'Buildings page loaded ' + buildingsList.length + ' buildings'});
          if(buildingsList.length===1){
            navigate(`/building/`+buildingsList[0].id);
          }
        }
          //ensure that refreshFlag is reset after refresh
          if(refreshFlag===true){
            setRefreshFlag(false);
          }
        }, [initialBuildings]);
   
    const addCancel = () => {
      setShowAddBuilding(false);
    };
  
    const addSuccess = () => {  
      setRefreshFlag(true);
      setShowAddBuilding(false);
      toast.success('הוספת בניין בוצעה בהצלחה!');
    };

    const addFailure = () => {  
      setShowAddBuilding(false);
      toast.error('הוספת בניין נכשלה!');
    };
    
    const handleAddBuildingClick = () => {
      setShowAddBuilding(true);
    };
    
    const dataFiltered = (filtered) => {
      setBuildingsList(filtered);
    }

return (
  <Fragment> 
  <Header/>
  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    {buildingsList ? 
    <div className='d-flex flex-column'>
      {!showAddBuilding &&
    <List 
    sx={{
      pt: 0,
      bgcolor: 'background.paper',
      boxShadow: 3,
      minWidth: 500
    }}>
      <ListItem sx={{backgroundColor:"grey.200"}} key="header1">
      <div className="d-flex flex-row justify-content-center align-items-center w-100">
        <Typography variant='h4' color="GrayText">רשימת הבניינים</Typography>
      </div>
      </ListItem>
      <ListItem alignItems='flex-start' key="header2">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <FilterList data={initialBuildings} onFilter={dataFiltered} fields="['title', 'address', 'city']"/>
          </div>
      </ListItem>
      <ListItem key="header3">
        <ListItemText sx={{textAlign:"start"}}>&nbsp;</ListItemText>
        <ListItemText sx={{textAlign:"start",fontWeight: 'bold'}} ><Typography  fontWeight="bold" >שם בניין</Typography></ListItemText>
        <ListItemText sx={{textAlign:"start",fontWeight: 'bold'}}><Typography fontWeight="bold" >כתובת</Typography></ListItemText>
        < ListItemText sx={{textAlign:"start",fontWeight: 'bold'}}><Typography fontWeight="bold" >עיר</Typography></ListItemText>
      </ListItem>
      {buildingsList && Array.isArray (buildingsList) && buildingsList.map((building) => (
        <div key={building.id}>
          <Divider variant="fullwidth" component="li" />
          <ListItem alignItems='flex-start' key={building.id} onClick={(e)=>{handleBuildingClick(e,building.id)}}
           sx={{
            '&:hover': {
              backgroundColor: 'grey.200' // change this to your preferred color
            },
          }}>
            <ListItemIcon>
                    <Apartment />
            </ListItemIcon>
            <ListItemText sx={{textAlign:"start"}} id={building.id} primary={building.title}  />
            <ListItemText sx={{textAlign:"start"}} id={building.id}  secondary={building.fullAddress.address} />
            <ListItemText  sx={{textAlign:"start"}} id={building.id}  secondary={building.fullAddress.city} />
          </ListItem>
        </div>
          ))}
        <ListItem key="footer">
        <ListItemText sx={{textAlign:"start"}}>
          <Button variant="contained" size="medium" startIcon={<Refresh />} onClick={()=>setRefreshFlag(true)} >&nbsp; רענון רשימה &nbsp;</Button>
        </ListItemText>
        <ListItemText sx={{textAlign:"end"}}>
          {isAdmin &&
              <Button variant="contained" size="medium" startIcon={<AddCircleOutline  />} onClick={()=>handleAddBuildingClick()} > 
                &nbsp;הוספת בניין&nbsp;
              </Button>
          }
        </ListItemText>
        </ListItem>

    </List>}
    {
    showAddBuilding && <AddBuilding onCancel={addCancel} onFailure={addFailure} onSuccess={addSuccess}/>
    }
    <Footer/>
    </div>
      : (
        <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
      )}
      
  </div>
  </Fragment>
  );
}
export default Buildings;