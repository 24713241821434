import React , { useState, useEffect, useContext} from 'react';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogActions, TextField, DialogContent, Button} from '@mui/material';
import { AuthContext, isAdmin } from '../authentication/AuthProvider';

export default function AddKey({show, buildingId, flatId, onAddKey, onComplete}) {
    const [open, setOpen] = React.useState(false);
    const [key, setKey] = useState('');
    const {requestHeaders, isAdmin} = useContext(AuthContext);
    const targetServer = process.env.REACT_APP_API_HOST;
    
    useEffect(() => {
        setOpen(show);
    }, [show]);

    const handleClose = () => {
        onComplete();
      };

    const handleAdd = () => {
          if(key.trim() === '') {
            toast.error('נא למלא את כל השדות הנדרשים');
            return;
          }
          else{
            addKey(key);
          }
        }
      

      const addKey =  async (key) => {
        const api = `api/building/${buildingId}/${flatId}/AddNfcTag`;
        console.log('Add key called!', api, key);
  
        const device = {
          key: key
        }
        try {
            const response = await fetch(targetServer + api, {
              method: 'POST',
              headers: requestHeaders,
              body: JSON.stringify(device),
            });
            if (response.ok) {
                const data = await response.json();
                console.log('key added successfully! Response:', data);
                toast.success('מפתח הוסף בהצלחה');
                onAddKey(); //callback to caller refresh device list
                handleClose();
                } else {
                const errorData = await response.text();
                console.error('Failed to add device! Error:', errorData);
                toast.error(`:שגיאה בהוספת מפתח ${errorData}`);
            }
          } catch (error) {
            toast.error(`Unexpected error: ${error.message}`);
            console.error(error);
          }
      };


      
    return(
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle>הוספת מפתח </DialogTitle>
    <DialogContent>
    <TextField
        required
        margin="dense"
        id="key"
        name="key"
        label={`מפתח`}
        type="text"
        onChange={(event) => setKey(event.target.value)}
        fullWidth
        variant="standard"/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>ביטול</Button>
      <Button onClick={handleAdd}>הוספה</Button>
    </DialogActions>
  </Dialog>);
};