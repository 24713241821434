
function GoogleSigninButton({post_login_redirect_url}) {
    
    return (
        <div>
        <a className='google-signin-button' href={`/.auth/login/google?post_login_redirect_url=${post_login_redirect_url}`}>
        Sign in with Google
       </a>
       </div>
    )
}

export default GoogleSigninButton