import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../authentication/AuthProvider";

export default function ResendActivationEmail(props){

    const [buildingId] = useState(props.buildingId);
    const [flatId] = useState(props.flatId);
    const [email] = useState(props.email);
    const [isActive, setIsActive] = useState(false);
    const { requestHeaders } = useContext(AuthContext);

    const resendActivationEmail = () => {
      setIsActive(true);
      const targetServer = process.env.REACT_APP_API_HOST
      const api = `api/building/${buildingId}/${flatId}/refreshEmail`
      const body = {
          email: email
      };
      fetch(targetServer + api, {
        method: 'POST', // or any other HTTP method
        headers: requestHeaders,
        body: JSON.stringify(body),
      }).then(response =>  {
        if (!response.ok) {
          toast.error('שליחת האימייל נכשלה');
        }
        else{
          toast.success('האימייל נשלח בהצלחה');
        }          
      }).then(data => {
          console.log('data', data);
        }
        )
        .catch(error => console.error(error));
      setIsActive(false);
    };

    return (
        <Fragment>
           {isActive ? <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} /> : 
            <Button variant="contained" color="primary" onClick={() => {resendActivationEmail()}}>שלח שוב אימייל לאימות</Button>
            }
        </Fragment>
    )
}