import React , {useContext, useState, useEffect} from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import {Button, Typography,Paper, Container, TextField, Grid} from '@mui/material';
import { Send, Close } from '@mui/icons-material';
import { AuthContext } from '../authentication/AuthProvider';

export default function  AddBuilding({onCancel, onSuccess, onFailure}) {

  const { requestHeaders, isAdmin} = useContext(AuthContext);
  const [doorCodeError, setDoorCodeError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [numFlatsError, setNumFlatsError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [startFlatNumError , setStartFlatNumError ] = useState(false);
  const [startingFlatNumber, setStartingFlatNumber] = useState(1);
  
  const cancel = () => {
    onCancel();
  }
  const handleAddBuildingClick = () => {
    var errorFound=false;
    
    const title = document.getElementsByName('title')[0]?document.getElementsByName('title')[0].value:undefined;
    if(!title){
      setTitleError(true);
      errorFound=true;
    }
    else
      setTitleError(false);

    const numflats = document.getElementsByName('numflats')[0]?parseInt(document.getElementsByName('numflats')[0].value):0;
    if(!numflats || numflats < 1 || numflats > 99){
      setNumFlatsError(true);
      errorFound=true;
    }
    else
      setNumFlatsError(false);
    
    const email = document.getElementsByName('email')[0]?document.getElementsByName('email')[0].value:undefined;
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
    if(!email || !regexEmail.test(email)){
      setEmailError(true);
      errorFound=true;
    }
    else
      setEmailError(false);

    const doorcode =document.getElementsByName('doorcode')[0]?document.getElementsByName('doorcode')[0].value:undefined;
    //check if doorcode is a 4 digit number
    const regex = new RegExp('^[0-9]{4}$');
    if(!doorcode || !regex.test(doorcode)){
      setDoorCodeError(true);
      errorFound=true;
    }
    else
      setDoorCodeError(false);

    const address = document.getElementsByName('address')[0]?document.getElementsByName('address')[0].value:undefined;
    if(!address){
      setAddressError(true);
      errorFound=true;
    }
    else
      setAddressError(false);

      const city = document.getElementsByName('city')[0]?document.getElementsByName('city')[0].value:undefined;
      if(!city){
        setCityError(true);
        errorFound=true;
      }
      else
        setCityError(false);

        const zip = document.getElementsByName('zip')[0]?document.getElementsByName('zip')[0].value:undefined;
        if(!zip){
          setZipError(true);
          errorFound=true;
        }
        else
          setZipError(false);

          const country = document.getElementsByName('country')[0]?document.getElementsByName('country')[0].value:undefined;
          if(!country){
            setCountryError(true);
            errorFound=true;
          }
          else
            setCountryError(false);

    if(errorFound)
      return;

    const building = {
      title: title,
      numflats: numflats,
      doorcode: doorcode,
      startingFlatNumber : startingFlatNumber,
      fullAddress: {
          city: document.getElementsByName('city')[0].value,
          address: document.getElementsByName('address')[0].value,
          country: document.getElementsByName('country')[0].value,
      },
      email: document.getElementsByName('email')[0].value
  };

  addBuilding(building);
  }

  const addBuilding = async (building) => {
    const targetServer = process.env.REACT_APP_API_HOST
    const api = 'api/addbuilding'
    try {
      const response = await fetch(targetServer + api, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(building),
      });
      const data = await response.json();
      console.log('Create building sucessful! response:', data);
      onSuccess();
    } catch (error) {
      console.error(error);
      onFailure();
    }
  };
  
  return (
    <React.Fragment>
    <CssBaseline />
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper  sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={3}>
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              הוספת בניין
            </Typography>
            <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="title"
            name="title"
            label="שם הבניין"
            fullWidth
            autoComplete="building-title"
            variant="standard"
            error={titleError}
            helperText={titleError ? "שם הבניין הוא שדה חובה " : " "}
          />
        </Grid>
        <Grid item xs={4}>
        <TextField
          required
          id="numflats"
          name="numflats"
          label="מספר דירות"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 1, max: 99 } }}
          variant="standard"
          error={numFlatsError }
          helperText={numFlatsError ? "מספר דירות חייב להיות בין 1 ל-99" : ""}
        />
        </Grid>

        <Grid item xs={4}>
        <TextField
          required
          id="startFlatNum"
          name="startFlatNum"
          label="מספר דירה ראשונה"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 1, max: 999 } }}
          variant="standard"
          error={startFlatNumError }
          helperText={startFlatNumError ? "מספר דירות חייב להיות בין 1 ל-999" : ""}
          onChange={(e) => {setStartingFlatNumber(e.target.value)}}
          value={startingFlatNumber}
        />
        </Grid>
        <Grid item xs={4}>
        <TextField
          required
          id="doorcode"
          name="doorcode"
          label="קוד פתיחת דלת"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 0, max: 9999 } }}
          variant="standard"
          error={doorCodeError}
          helperText={doorCodeError ? "קוד פתיחת דלת חייב להיות בן 4 ספרות" : ""}
        />
        </Grid> 
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="דואר אלקטרוני"
            fullWidth
            autoComplete="shipping email"
            variant="standard"
            error={emailError}
            helperText={emailError ? "דואר אלקטרוני לא תקין" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="כתובת"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            error={addressError}
            helperText={addressError ? "כתובת היא שדה חובה" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="עיר"
            fullWidth
            autoComplete="shipping city"
            variant="standard"
            error={cityError}
            helperText={cityError ? "עיר היא שדה חובה" : ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="מיקוד"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            error={zipError}
            helperText={zipError ? "מיקוד הוא שדה חובה" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="country"
            name="country"
            label="מדינה"
            value="ישראל"
            fullWidth
            variant="standard"
            error={countryError}
            helperText={countryError ? "מדינה היא שדה חובה" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" size="medium" startIcon={<Close />} onClick={()=>{cancel()}}>&nbsp; ביטול &nbsp;</Button>
          <Button variant="contained" size="medium" endIcon={<Send  style={{ transform: 'scaleX(-1)' }} />} sx={{m:1}} onClick={()=>{handleAddBuildingClick()}}>&nbsp; עדכון &nbsp;</Button>
        </Grid>
      </Grid>
            </React.Fragment>
      </Paper>
    </Container>
  </React.Fragment>
  );
}

